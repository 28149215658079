import i18n from '@/boot/i18n'
import store from '@/boot/store'
import { title } from '@/utilities/filters'
import {
  isInteger,
  isGreaterThanOrEqualToN,
  isLessThanOrEqualToN,
  isRequired,
} from '@/utilities/validations'

export const CUSTOM_PRICE_LIST_PREFIX = 'cus'
export const DEFAULT_PRICE_LIST_PREFIX = 'def'

export const FIELDS = [
  'originRegion',
  'priceList',
  'description',
  'quantity',
  'total',
  'dateBilled',
]

export const customPriceLists = () =>
  store.getters['billing/getManualPriceLists']?.data?.map(c => ({
    ...c,
    id: CUSTOM_PRICE_LIST_PREFIX + c.id,
  })) || []

export const defaultPriceLists = () =>
  store.getters['billing/getManualDefaultPriceLists']?.data?.map(d => ({
    ...d,
    id: DEFAULT_PRICE_LIST_PREFIX + d.id,
  })) || []

export const customPriceListsByRegion = () =>
  [...customPriceLists()]?.filter(
    d => d.originRegion === store.getters['billing/getSelectedRegion']
  )

export const defaultPriceListsByRegion = () =>
  [...defaultPriceLists()]
    ?.filter(d => d.originRegion === store.getters['billing/getSelectedRegion'])
    ?.filter(d => d.currency === store.getters['billing/getSelectedBox']?.currency)

export const getPriceListByPriceListId = id =>
  [...customPriceLists(), ...defaultPriceLists()].find(p => p.id === id)

export const getPriceListIdByRealIds = (defaultId, customId) => {
  if (defaultId) return DEFAULT_PRICE_LIST_PREFIX + defaultId
  else if (customId) return CUSTOM_PRICE_LIST_PREFIX + customId
  return null
}

export const getRealIdsByPriceListId = id => {
  const ids = {
    manualDefaultPriceListId: null,
    manualPriceListId: null,
  }
  if (id && typeof id === 'string') {
    const prefix = id.substring(0, 3)
    const realId = id.substring(3)
    if (prefix === DEFAULT_PRICE_LIST_PREFIX) ids.manualDefaultPriceListId = realId
    else if (prefix === CUSTOM_PRICE_LIST_PREFIX) ids.manualPriceListId = realId
  }
  return ids
}

export const priceListField = () => {
  const customHeader = { header: 'Custom' }
  const defaultHeader = [{ divider: true }, { header: 'Default' }]
  return {
    name: 'priceList',
    validationName: 'manualDefaultPriceListId',
    label: i18n.t('billing.priceList'),
    component: 'v-select',
    items: [
      customHeader,
      ...customPriceListsByRegion(),
      ...defaultHeader,
      ...defaultPriceListsByRegion(),
    ],
    itemText: 'name',
    itemValue: 'id',
    placeholder: title(i18n.t('ui.pleaseSelectAX', { x: i18n.t('billing.priceList') })),
  }
}

export const descriptionField = () => {
  return {
    name: 'description',
    label: title(i18n.t('common.description')),
    type: 'text',
    rules: [isRequired],
  }
}

export const quantityField = () => {
  return {
    name: 'quantity',
    type: 'number',
    label: title(i18n.t('common.quantity')),
    min: 0,
    max: 1000000,
    placeholder: '0',
    step: 1,
    rules: [
      v => isInteger(v),
      v => isGreaterThanOrEqualToN(v, 0),
      v => isLessThanOrEqualToN(v, 1000000),
    ],
  }
}

export const totalField = () => {
  return {
    name: 'total',
    type: 'number',
    label: title(i18n.t('common.total')),
    placeholder: '0.00',
    readonly: true,
    disabled: true,
  }
}

export const dateField = () => {
  return {
    name: 'dateBilled',
    label: title(i18n.t('billing.billingDate')),
    component: 'huboo-date-picker',
    placeholder: 'YYYY-MM-DD',
  }
}

export const originRegionField = () => {
  return {
    name: 'originRegion',
    label: title(i18n.t('billing.originRegion')),
    type: 'string',
    component: 'v-select',
    items: store.getters['billing/getRegions']?.data?.filter(r => r.origin) || [],
    itemText: 'displayName',
    itemValue: 'code',
    rules: [isRequired],
  }
}
