import i18n from '@/boot/i18n'
import { isFuture as dateFnsIsFuture, isPast as dateFnsIsPast, isValid } from 'date-fns'

export const endsInDecimalPoint = v => String(v).lastIndexOf('.') === String(v).length - 1

export const isEmailAddress = v =>
  !v || isEmailAddressCheck(v) || i18n.t('validations.mustBeAValidEmailAddress')

/**
 * Returns true if a valid email address.
 *
 * @param {String} str string to be checked
 *
 * @returns {Boolean} true if string is a valid email address
 */
export function isEmailAddressCheck(str) {
  let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(str).toLowerCase())
}

export const isFloat = (v, strict) =>
  strict
    ? Number(v) === v
    : Number(v) == v || endsInDecimalPoint(v) || i18n.t('validations.mustBeAFloat')

export const isFuture = v =>
  dateFnsIsFuture(new Date(v)) || i18n.t('validations.thisDateMustBeInTheFuture')

export const isGreaterThanN = (v, n) => v > n || i18n.t('validations.mustBeGreaterThanN', { n })

export const isGreaterThanOrEqualToN = (v, n) =>
  v >= n || i18n.t('validations.mustBeGreaterThanOrEqualToN', { n })

export const isInteger = (v, strict = false) =>
  Number.isInteger(strict ? v : Number(v)) || i18n.t('validations.mustBeAnInteger')

export const isLessThanN = (v, n) => v < n || i18n.t('validations.mustBeLessThanN', { n })

export const isLessThanOrEqualToN = (v, n) =>
  v <= n || i18n.t('validations.mustBeLessThanOrEqualToN', { n })

export const isPast = v =>
  dateFnsIsPast(new Date(v)) || i18n.t('validations.thisDateMustBeInThePast')

export const isRequired = v =>
  String(v).trim().length > 0 || i18n.t('validations.thisFieldIsRequired')

export const isRoundedToNDecimalPlaces = (v, n, exact = false) => {
  const exploded = String(v).split('.')
  const eLen = exploded.length
  if (eLen === 1) return true // number is whole
  if (eLen > 2) return i18n.t('validations.mustHaveASingleDecimalPoint') // not a decimal
  const length = exploded[eLen - 1].length
  if (exact)
    return length === n || i18n.t('validations.mustBeRoundedToExactlyNDecimalPlaces', { n })
  else return length <= n || i18n.t('validations.mustBeRoundedToNDecimalPlaces', { n })
}

export const isValidDate = v =>
  isValid(new Date(v)) || i18n.t('validations.thisFieldMustBeAValidDate')

export const maxChar = n => v =>
  !v || String(v).length <= n || i18n.t('validations.maxNCharacters', { n })
