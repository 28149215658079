<template>
  <huboo-page>
    <huboo-page-heading :heading="$t('billing.manualBilling') | title"></huboo-page-heading>

    <huboo-table
      id="manualBillingTable"
      @row-clicked="onRowSelected"
      @search="handleSearch"
      @filter="handleFilter"
      @remove="onRemove"
      @update:options="handleUpdateOptions"
      :filters="filters"
      :headers="headers"
      :items="items"
      v-bind="options"
      clickable
      remove
      :server-items-length="serverItemsLength"
      :loading="loading"
    >
      <template #prepend v-if="totalSum">
        <span class="black--text text-h6 font-weight-black">
          {{ $t('common.total') + ': ' + totalSum + ' ' + currency }}
        </span>
      </template>

      <template #actions>
        <export-button
          v-if="exportUrl"
          :file-name="exportName"
          :export-url="exportUrl"
          :client="box.hubooBox.toString()"
        />

        <v-btn
          :disabled="loading"
          dark
          color="primary"
          @click="onCreateSelected"
          id="manualBillingCreateButton"
        >
          {{ $t('billing.createManualBilling') }}
        </v-btn>
      </template>

      <template v-slot:filter>
        <huboo-date-range-picker
          @update="updateDateRange"
          modal-width="300px"
        ></huboo-date-range-picker>
      </template>
    </huboo-table>

    <component
      :is="modal.component"
      v-model="modal.show"
      :selected="selected"
      @selected:updated="onSelectedUpdated"
    ></component>
  </huboo-page>
</template>

<script>
import ManualBillingCreateModal from '@/views/pages/billing/manualBilling/ManualBillingCreateModal'
import ManualBillingEditModal from '@/views/pages/billing/manualBilling/ManualBillingEditModal'
import {
  getPriceListIdByRealIds,
  getPriceListByPriceListId,
} from '@/views/pages/billing/manualBilling/manualBillingModalHelpers'
import { title } from '@/utilities/filters'
import ClientMixin from '@/mixins/client'
import ExportButton from '@/components/pages/ExportButton.vue'

export default {
  name: 'ManualBilling',
  mixins: [ClientMixin],
  components: {
    'manual-billing-create-modal': ManualBillingCreateModal,
    'manual-billing-edit-modal': ManualBillingEditModal,
    'export-button': ExportButton,
  },
  data: () => ({
    modal: {
      component: '',
      show: false,
    },
    options: {
      page: 1,
      itemsPerPage: 10,
      sortBy: [],
      sortDesc: [],
      groupBy: [],
      groupDesc: [],
      multiSort: false,
      mustSort: false,
    },
    search: '',
    selected: null,
  }),
  computed: {
    exportUrl() {
      return this.billingManualHeaders?.exportUrl
    },
    exportName() {
      return this.$options.name
    },
    headers() {
      return [
        {
          text: title(this.$t('billing.billingDate')),
          sortable: false,
          value: 'dateBilledFormatted',
        },
        { text: title(this.$t('common.name')), sortable: false, value: 'displayName' },
        { text: title(this.$t('common.description')), sortable: false, value: 'description' },
        { text: title(this.$t('common.quantity')), sortable: false, value: 'quantity' },
        { text: title(this.$t('billing.originRegion')), sortable: true, value: 'originRegion' },
        { text: title(this.$t('common.total')), sortable: false, value: 'total' },
      ]
    },
    billingManualHeaders() {
      return this.$store.getters['billing/getBillingManualHeaders']
    },
    items() {
      return (
        this.billingManualHeaders?.data?.map(m => {
          const priceList = getPriceListIdByRealIds(m.manualDefaultPriceListId, m.manualPriceListId)
          return {
            ...m,
            dateBilled: m.dateBilled.substr(0, 10),
            dateBilledFormatted: this.formatDate(m.dateBilled),
            priceList,
            displayName: getPriceListByPriceListId(priceList)?.name,
          }
        }) || []
      )
    },
    loading() {
      return this.$store.getters['core/apiEndpointIsLoading']({
        method: 'GET',
        url: this.url,
      })
    },
    meta() {
      return this.billingManualHeaders?.meta || { total: this.items.length }
    },
    totalSum() {
      return this.billingManualHeaders?.totalSum
    },
    removeAction() {
      return 'billing/removeBillingManualHeaders'
    },
    url() {
      return '/huboo-box/' + this.box?.hubooBox + '/billing-manual-headers'
    },
  },
  watch: {
    'modal.show': function(s) {
      if (s === false) this.fetch()
    },
    options: {
      handler() {
        this.fetch()
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    this.$store.dispatch('billing/fetchManualDefaultPriceLists')
    this.fetchManualPriceLists()
    this.$store.dispatch('billing/fetchRegions')
  },
  methods: {
    fetch() {
      if (!this.loading) {
        const { options = {}, orderBy, search, dateRange } = this
        const { page, itemsPerPage } = options
        const params = {}
        if (page) params.page = page
        if (itemsPerPage) params.itemsPerPage = itemsPerPage
        if (search) params.query = search
        if (orderBy) params.orderBy = orderBy
        if (dateRange) params.date_billed = dateRange?.join(',')
        this.$store.dispatch('billing/fetchBillingManualHeaders', { params, url: this.url })
      }
    },
    fetchManualPriceLists() {
      const params = { url: '/huboo-box/' + this.box?.hubooBox + '/manual-price-list' }
      this.$store.dispatch('billing/fetchManualPriceLists', params)
    },
    onRowSelected(v) {
      this.selected = v
      this.modal = { component: 'manual-billing-edit-modal', show: true }
      this.$store.commit('billing/setSelectedRegion', v.originRegion)
    },
    onCreateSelected() {
      this.modal = { component: 'manual-billing-create-modal', show: true }
    },
    onSelectedUpdated() {
      const s = this.$store.getters['billing/getSelectedBillingManualHeader']
      const priceList = getPriceListIdByRealIds(s.manualDefaultPriceListId, s.manualPriceListId)
      this.selected = {
        ...s,
        dateBilled: s.dateBilled.substr(0, 10),
        priceList,
        displayName: getPriceListByPriceListId(priceList)?.name,
      }
    },
    async onRemove({ id }) {
      await this.$store.dispatch(this.removeAction, { id, url: this.removeUrl(id) })
      this.fetch()
    },
    removeLoading(id) {
      return this.$store.getters['core/apiEndpointIsLoading']({
        url: this.removeUrl(id),
        method: 'DELETE',
      })
    },
    removeUrl(id) {
      return 'billing-manual-header/' + id
    },
  },
}
</script>

<style lang="scss" scoped></style>
