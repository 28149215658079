<template>
  <huboo-full-screen-dialog
    :value="value"
    @input="$emit('input', $event)"
    :title="$t('billing.manualBilling') | title"
  >
    <huboo-list
      max-width="600px"
      class="pa-4 mx-auto text-center"
      clickable
      @clicked="handleItemClicked"
      :items="items"
      :nullText="$t('common.none') | title"
    >
    </huboo-list>
    <huboo-modal
      v-for="(item, index) in items"
      :key="'editModal' + index"
      maxWidth="600px"
      v-model="editModals[item.name]"
    >
      <v-card>
        <huboo-form
          id="manualBillingForm"
          :title="$t('common.edit') | title"
          @submit="onSubmit"
          :fields="[item]"
          v-if="editModals[item.name]"
          :loading="loadingForm"
        >
        </huboo-form>
      </v-card>
    </huboo-modal>
  </huboo-full-screen-dialog>
</template>

<script>
import { isWithinInterval, startOfMonth, endOfMonth } from 'date-fns'
import {
  dateField,
  descriptionField,
  FIELDS,
  getPriceListByPriceListId,
  getRealIdsByPriceListId,
  originRegionField,
  priceListField,
  quantityField,
  totalField,
} from '@/views/pages/billing/manualBilling/manualBillingModalHelpers'

export default {
  name: 'ManualBillingEditModal',
  props: {
    value: Boolean,
    selected: { type: Object, required: true },
  },
  data: () => ({
    editModals: FIELDS.reduce((a, v) => {
      a[v] = false
      return a
    }, {}),
  }),
  computed: {
    fields() {
      return {
        originRegion: originRegionField(),
        priceList: priceListField(),
        description: descriptionField(),
        quantity: quantityField(),
        total: totalField(),
        dateBilled: dateField(),
      }
    },
    items() {
      return FIELDS.map(f => ({ ...this.fields[f], name: f })).map(f => ({
        ...f,
        readonly: this.readonly || f.name === 'originRegion',
        errorMessages: this.$store.getters['core/validationError'](f.validationName || f.name),
        text: f.name === 'priceList' ? this.selected?.displayName : this.selected?.[f.name],
        title: f.label,
        value: this.selected?.[f.name],
      }))
    },
    hasErrors() {
      return this.$store.getters['core/hasErrors']
    },
    loadingForm() {
      return this.$store.getters['core/apiEndpointIsLoading']({
        method: 'PATCH',
        url: 'billing-manual-header/' + this.selected?.id,
      })
    },
    readonly() {
      const now = Date.now()
      const range = { start: startOfMonth(now), end: endOfMonth(now) }
      return !isWithinInterval(Date.parse(this.selected?.dateBilled), range)
    },
  },
  methods: {
    getTotal(quantity, priceListId) {
      const priceListPrice = getPriceListByPriceListId(priceListId)?.price || 0
      return (quantity * priceListPrice).toFixed(2)
    },
    handleItemClicked(e) {
      this.editModals[e.name] = true
    },
    async onSubmit({ fields, values }) {
      const data = { ...this.selected, ...values }
      await this.$store.dispatch('billing/patchBillingManualHeaders', {
        id: this.selected?.id,
        data: {
          ...data,
          ...getRealIdsByPriceListId(data.priceList),
          total: this.getTotal(data.quantity, data.priceList),
        },
      })
      if (!this.hasErrors) {
        this.$emit('selected:updated')
        fields.forEach(f => {
          this.editModals[f.name] = false
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
