<template>
  <huboo-full-screen-dialog
    :value="value"
    @input="$emit('input', $event)"
    :title="$t('billing.manualBilling') | title"
  >
    <v-card class="pa-4 create-button-container mx-auto mt-4">
      <huboo-form
        :key="formKey"
        :title="$t('common.create') | title"
        @input="onInput"
        :fields="fields"
        :loading="loadingForm"
        lazy-validation
        id="manualBillingCreateForm"
      >
      </huboo-form>
      <div class="px-5 pb-5 black--text">
        <h3>{{ $t('common.total') + ': ' + currency + ' ' + total }}</h3>
      </div>
      <v-btn
        color="primary"
        dark
        large
        width="100%"
        @click="onSubmit"
        id="manualBillingCreateModalButton"
      >
        {{ $t('common.create') }}
      </v-btn>
    </v-card>
  </huboo-full-screen-dialog>
</template>

<script>
import {
  dateField,
  descriptionField,
  getPriceListByPriceListId,
  getRealIdsByPriceListId,
  originRegionField,
  priceListField,
  quantityField,
} from '@/views/pages/billing/manualBilling/manualBillingModalHelpers'
import ClientMixin from '@/mixins/client'

export default {
  name: 'ManualBillingCreateModal',
  mixins: [ClientMixin],
  props: {
    value: Boolean,
  },
  data: () => ({
    formKey: 0,
    newModel: {},
    disablePriceList: true,
  }),
  computed: {
    fields() {
      return [
        originRegionField(),
        priceListField(),
        descriptionField(),
        quantityField(),
        dateField(),
      ].map(f => ({
        ...f,
        errorMessages: this.$store.getters['core/validationError'](f.validationName || f.name),
        title: f.label,
        text: this.newModel?.[f.name],
        value: this.newModel?.[f.name],
        disabled: f.name === 'priceList' && this.disablePriceList,
      }))
    },
    hasErrors() {
      return this.$store.getters['core/hasErrors']
    },
    hubooBox() {
      return this.$store.getters['billing/getSelectedBox']?.hubooBox
    },
    loadingForm() {
      return this.$store.getters['core/apiEndpointIsLoading']({
        method: 'POST',
        url: 'billing-manual-header',
      })
    },
    total() {
      return (
        this.newModel?.quantity * getPriceListByPriceListId(this.newModel?.priceList)?.price || 0
      ).toFixed(2)
    },
  },
  watch: {
    newModel(newValue) {
      if (newValue.originRegion !== undefined) {
        this.disablePriceList = false
        this.$store.commit('billing/setSelectedRegion', newValue.originRegion)
      }
    },
  },
  methods: {
    onInput({ field, value }) {
      this.newModel = { ...this.newModel, [field.name]: value }
    },
    async onSubmit() {
      await this.$store.dispatch('billing/postBillingManualHeaders', {
        ...this.newModel,
        ...getRealIdsByPriceListId(this.newModel?.priceList),
        total: this.total,
        hubooBox: this.hubooBox,
      })
      if (!this.hasErrors) {
        this.$emit('input', false)
        this.newModel = {}
        this.formKey += 1
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.create-button-container {
  max-width: 600px;
}
</style>
